.App {
  text-align: center;
 
}
body{
  background: rgb(185,250,196);
  background: linear-gradient(110deg, rgba(185,250,196,1) 30%, rgba(205,213,249,1) 100%);
  background-repeat: no-repeat;
  background-size: cover;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  body{
    background-image: url('./assets/background-image.svg') !important;
    background-size: cover;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.card-layout{
  width: 450px;
  min-height: 350px;
  padding: 10px;
  -webkit-box-shadow: 0px 0px 5px 3px rgba(0,0,0,0.10);
-moz-box-shadow: 0px 0px 5px 3px rgba(0,0,0,0.10);
box-shadow: 0px 0px 5px 3px rgba(0,0,0,0.10);
}

.pallet-logo{
  width: 130px;
  height: 100px;
}

.hr-line{
  border-bottom: 1px solid #D2D2D2;

}
.common-border{
  height: 1px;
  color: #D2D2D2;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.float-left{
  float:left !important;
}

.common-label{
  font-weight: 500;
  font-size: 20px;
  line-height: 23.44px;
  text-transform: capitalize;
}
.h-40{
  height: 45px;
}

.btn-custom{
  background-color: #61CE5A;
  color: #000;
  border-radius: 30%; 
  width: 70%;
  padding: 10px;
}

/* CSS */
.button-18 {
  align-items: center;
  background-color: #61CE5A;
  border: 0;
  border-radius: 100px;
  box-sizing: border-box;
  color: #000;
  cursor: pointer;
  display: inline-flex;
  font-family: -apple-system, system-ui, system-ui, "Segoe UI", Roboto, "Helvetica Neue", "Fira Sans", Ubuntu, Oxygen, "Oxygen Sans", Cantarell, "Droid Sans", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Lucida Grande", Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: 500;
  justify-content: center;
  line-height: 20px;
  max-width: 480px;
  min-height: 40px;
  min-width: 150px;
  overflow: hidden;
  padding: 0px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
  touch-action: manipulation;
  transition: background-color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s, box-shadow 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s, color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s;
  user-select: none;
  -webkit-user-select: none;
  vertical-align: middle;
}

.button-18:hover{ 
  background-color: #000;
  color: #ffffff;
}

.button-18:active {
  background: #61CE5A;
  color: rgb(255, 255, 255);
}

.button-18:disabled { 
  cursor: not-allowed;
  background: rgba(0, 0, 0, .08);
  color: rgba(0, 0, 0, .3);
}

.float-right{
  float: right;
}
.w-5{
  width: 12.5%;
}

.close-btn{
  background-color: #D5DBD4;
}
.close-btn :hover{
  background-color: #000 ;
}

.webcam-layout{
  max-width: 450px !important;
}

.modal{
  height: 500px;
}

.css-b62m3t-container{
  width: 375px;
  text-align: start;
}

.css-13cymwt-control{
  height: 42px;
}
/* .css-13cymwt-control > input{
  padding-left: 0px;
}

.css-1jqq78o-placeholder{
  margin-left: -152px !important;
}
.css-1dimb5e-singleValue{
  margin-left: -25% !important;
} */

.error-msg{
  margin-top: -4%;
}

.eye-icon{
  position: absolute;
  top: 45%;
  right: 5%;
}

.logout-btn{
  position: absolute;
  top: 3%;
  right: 3%;
}
